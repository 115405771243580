import React, { useState } from 'react';
import emailjs from 'emailjs-com';

export function Contact() {
    let [resMessage, setResMessage] = useState("");
    emailjs.init('lcDu_nWUFO5rDfazh');

    const sendEmail = (e) =>{
        e.preventDefault();

        emailjs.sendForm('service_w5cl8vn', 'template_38kzshj', e.target)
        .then(function(res) {
            console.log(res.text)
            console.log("SUCCESS");
            setResMessage("Message sent, thank you!");
            e.target.reset();
        }, function(error) {
            console.log(error);
            setResMessage("Uhoh, something went wrong :(");
            console.log("FAILURE");
        });
    }
    return (
        <div className="d-flex flex-column align-items-center" >
            <div className="w-75 mt-4 h4">Send Me Something!</div>
            <div className='w-75 mb-2'>
                <form id="contactForm" className="border rounded p-3 mt-2 column px-4" onSubmit={sendEmail}>
                    <div className='row'>
                        <div className="form-group px-2 col-6">
                            <input type="text" className="form-control" name="name" id="name" placeholder="Billy Bill" onChange={()=>setResMessage("")} required/>
                            <label className="w-100 text-left p-1" htmlFor="name">Your Name</label>
                        </div>
                        <div className="form-group px-2 col-6">
                            <input type="email" className="form-control" name="email" id="email" placeholder="billy@bill.edu" onChange={()=>setResMessage("")} required/>
                            <label className="w-100 text-left p-1" htmlFor="email">Your Email</label>
                        </div>
                    </div>
                    <div className="form-group px-2 row">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Something Fun!" onChange={()=>setResMessage("")} required/>
                        <label className="text-left p-1" htmlFor="subject">Subject</label>
                    </div>
                    <div className="form-group px-2 mb-0 row">
                        <textarea className="form-control" name="message" id="message" placeholder='Yeehaw' onChange={()=>setResMessage("")} required/>
                        <label className="text-left p-1 pb-0" htmlFor="message">Message</label>
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <button type="submit" className="btn btn-primary px-5 pt-2">Send!</button>
                    </div>
                    <p className='d-flex flex-row-start m-0'><em>{resMessage}</em></p>
                </form>
            </div>
        </div>

    //     <div class="px-4 mb-5 contact-container">
    //         <h1 class="headingText">Send me something!</h1>
    //         <br />
    //         <form class="px-5 mx-5" id="contact" onSubmit={sendEmail}>
    //             <div class="row">
    //                <div class="col-md-6">
    //                    <div class="md-form mb-0 justify-content-start">
    //                        <input type="text" id="name" name="name" class="form-control" placeholder="Billy Bill"/>
    //                        <label for="name" class="d-flex align-items-start">Your Name</label>
    //                     </div>
    //                </div>
    //                <div class="col-md-6">
    //                    <div class="md-form mb-0">
    //                        <input type="text" id="email" name="email" class="form-control" placeholder="billy@bill.com"/>
    //                        <label for="email" class="d-flex align-items-start">Your Email</label>
    //                     </div>
    //                </div>
    //             </div>
    //             <div class="row">
    //                 <div class="col-md-12">
    //                    <div class="md-form mb-0">
    //                        <input type="text" id="subject" name="_subject" class="form-control" placeholder="Howdy!"/>
    //                        <label for="subject" class="d-flex align-items-start">Subject</label>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="row">
    //                 <div class="col-md-12">
    //                    <div class="md-form mb-0">
    //                        <textarea type="text" id="message" name="message" class="form-control" placeholder="Yeehaw :)"/>
    //                        <label for="message" class="d-flex align-items-start">Message</label>
    //                     </div>
    //                 </div>
    //             </div>
    //             <button type="submit" class="mt-2 btn contactButton btn-lg d-flex align-items-start">Send!</button>
    //             <p class="mt-2 d-flex align-items-start">{resMessage}</p>
    //        </form>
    //     </div>
     )
};

export default Contact;
