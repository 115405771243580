import React, { useState } from 'react';
import { ListModal } from './components/ListModal.js';
import { Resume } from './components/Resume.js';
import Courses from '../json/Courses.json';
import Experience from '../json/Experience.json';
import Languages from '../json/Languages.json';
import Technologies from '../json/Technologies.json';
import Education from '../json/Education.json';

export function TechBg() {
    let [courseShow, setCourseShow] = useState(false);
    let [expShow, setExpShow] = useState(false);
    let [langShow, setLangShow] = useState(false);
    let [techShow, setTechShow] = useState(false);
    let [eduShow, setEduShow] = useState(false);

    let [courseList, setCourseList] = useState(Courses["Coursework"]);
    let [expList, setExpList] = useState(Experience["Experience"]);
    let [langList, setLangList] = useState(Languages["Languages"]);
    let [techList, setTechList] = useState(Technologies["Technologies"]);
    let [eduList, setEduList] = useState(Education["Education"]);



    const handleClose = () => {
        setCourseShow(false);
        setExpShow(false);
        setLangShow(false);
        setTechShow(false);
        setEduShow(false);
    }

    const handleShowCourses = () => {
        setCourseShow(true);
    }
    const handleShowExp = () =>{
        setExpShow(true);
    } 
    const handleShowLang = () =>{
        setLangShow(true);
    }
    const handleShowTech = () => {
        setTechShow(true);
    }
    const handleShowEdu = () => {
        setEduShow(true);
    }

    return (
    <div className="w-90 container" >
        <div className="row mt-5">
            <div className="col-7">
                <Resume />
            </div>
            <div className="col-5">
                <button className="btn btn-outline-primary w-100 mt-3 p-3" onClick={handleShowCourses}>Relevant Coursework</button>
                <button className="btn btn-outline-primary w-100 mt-3 p-3" onClick={handleShowExp}>Experience</button>
                <button className="btn btn-outline-primary w-100 mt-3 p-3" onClick={handleShowLang}>Languages</button>
                <button className="btn btn-outline-primary w-100 mt-3 p-3" onClick={handleShowTech}>Technologies Used</button>
                <button className="btn btn-outline-primary w-100 mt-3 p-3" onClick={handleShowEdu}>Education</button>

            </div>

        </div>
        <ListModal show={courseShow} handleClose={handleClose} title="Relevant Coursework" list={courseList} />
        <ListModal show={expShow} handleClose={handleClose} title="Experience" list={expList}/>
        <ListModal show={langShow} handleClose={handleClose} title="Languages" list={langList} />
        <ListModal show={techShow} handleClose={handleClose} title="Technologies Used" list={techList}  />
        <ListModal show={eduShow} handleClose={handleClose} title="Education" list={eduList}  />
    </div>
    )
};

