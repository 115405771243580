import axios from "axios";
import React, {useEffect, useState} from 'react';

export function Weather() {
    let [city, setCity] = useState("hoboken")
    let [allWeather, setAllWeather] = useState(undefined)
    let [weather, setWeather] = useState(undefined)
    let [temp, setTemp] = useState(undefined)
    let [wind, setWind] = useState(undefined)
    let [cloud, setCloud] = useState(undefined)
    let [iconUrl, setIconUrl] = useState(undefined)
    let [hideError, setHideError] = useState(true)
    let [location, setLocation] = useState(undefined)
    let [coords, setCoords] = useState(city)

    //make api call
    //q can be zip for zipcode
    let apiUrl=`https://api.openweathermap.org/data/2.5/weather?q=${city}&exclude=hourly,daily&units=metric&appid=1af4314ecee45f33d498e6f9beb83e3f`
    let locUrl = `http://api.openweathermap.org/geo/1.0/reverse?lat=${coords.lat}&lon=${coords.lon}&limit=1&appid=1af4314ecee45f33d498e6f9beb83e3f`
    useEffect(() => {
        async function getWeather(){
            try {
                let {data} = await axios.get(apiUrl);
                setAllWeather(data);
                setHideError(true);
                return;
            } catch(e) {
                setHideError(false);
                setCity("try again!")
                return;
            }
        }
        getWeather();
    }, [apiUrl, locUrl])

    useEffect(()=>{
        async function getLocation(){
            try {
                let {data} = await axios.get(locUrl);
                setLocation(data[0]);
                return;
            } catch(e) {
                //console.log(e)
                return;
            }
        }
        getLocation()
    },[coords])

    useEffect(() => {
        if (allWeather) {
            Object.keys(allWeather["main"]).forEach(function(key) {
                allWeather["main"][key] = Math.round(allWeather["main"][key])
            });
            Object.keys(allWeather["wind"]).forEach(function(key) {
                allWeather["wind"][key] = Math.round(allWeather["wind"][key])
            });
            setTemp(allWeather.main)
            setWind(allWeather.wind)
            setCloud(allWeather.clouds)
            setCoords(allWeather.coord)
            if (allWeather.weather[0]){
                setWeather(allWeather.weather[0])
                setIconUrl(`https://openweathermap.org/img/wn/${allWeather.weather[0].icon}.png`)
            }
        }
    }, [allWeather])

    async function searchWeather(e){
        e.preventDefault()
        let newCity = document.getElementById("zipcodeSearch").value
        setCity(newCity)
    }

    return (
        <div className="d-flex flex-column justify-content-center border rounded p-4 pb-2" >
            <div className="row">
                <form className="inline-form" onSubmit={searchWeather}>
                    <label htmlFor="zipcodeSearch">Weather in </label>
                    <input className="ml-2" placeholder={city.charAt(0).toUpperCase()+city.slice(1)} id="zipcodeSearch"/>
                    <button type="submit" className="btn btn-primary btn-sm ml-2 mb-1">Go!</button>
                </form>
            </div>
            <div className="row pt-2">
                <div className="col-5" >
                    <img src={iconUrl && iconUrl} className="w-50"/>
                    <div>
                        {weather && weather.description}
                    </div>
                </div>
                <div className="col-7" >
                    <div className="row" >
                        Today's Temp: {temp && temp.temp_min}{'\u00B0C'} / {temp && temp.temp_max}{'\u00B0C'}
                    </div>
                    <div className="row" >
                        Currently: {temp && temp.temp}{'\u00B0C'}
                    </div>
                    <div className="row" >
                        Feels Like: {temp && temp.feels_like}{'\u00B0C'}
                    </div>
                    <div className="row" >
                        Wind: {wind && wind.speed} meter/sec
                    </div>
                    <div className="row" >
                        Clouds: {cloud && cloud.all}%
                    </div>
                </div>
            </div>
            <p className="font-weight-light font-italic pt-2 small">({location && location.name}, {location && location.state})</p>
            <p className="text-danger" hidden={hideError}>Uhoh, I don't kno where that is!</p>
        </div>
    )
};

