import { Weather } from './components/Weather';
import { Trivia } from './components/Trivia';
import { Hobby } from './components/Hobby';
import { ClockBar } from './components/ClockBar';


export function Home() {
  return (
    <div className="w-90 container rule-1" >
        <div className="row mt-3">
            <div className="col-7">
                <div className="row border h-50 rounded mb-2">
                    <div id="clockContainer" className="p-0">
                        <ClockBar />
                    </div>
                </div>
                <div className="row h-50 d-flex justify-content-between">
                    <div id="triviaContainer" className="p-0">
                        <Trivia />
                    </div>
                    <div id="hobbyContainer" className="p-0">
                        <Hobby />
                    </div>            
                </div>
            </div>
            <div className="col-5">
                <Weather />
                <button className="btn btn-outline-primary w-100 mt-3 p-3" onClick={()=>window.location.href="/about"}>About Me</button>
                <button className="btn btn-outline-primary w-100 mt-3 p-3" onClick={()=>window.location.href="/techBackground"}>Experience</button>
                <button className="btn btn-outline-primary w-100 mt-3 p-3" onClick={()=>window.location.href="/contact"}>Contact Me</button>
            </div>
        </div> 
    </div>
);
}