import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import MyResume from "../../files/resume_kaiqichee.pdf";   
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function Resume() {
    let [pageNum, setPageNum] = useState(1);

    return (
        <div id="resumeContainer" className="d-flex flex-column justify-content-center border rounded w-100 h-100 overflow-scroll">
            <Document file={MyResume} onLoadError={console.error}>
                <Page pageNumber={pageNum} renderTextLayer={false} renderAnnotationLayer={false}/>
            </Document>
        </div>
    );
};