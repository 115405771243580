import React, {useState, useEffect} from 'react';
import axios from 'axios';
export function Hobby() {
    let [hobby, setHobby] = useState(undefined);
    let [info, setInfo] = useState(undefined);
    let [another, setAnother] = useState(false);

    useEffect(()=>{
        async function getHobby() {
            let {data} = await axios.get("https://api.api-ninjas.com/v1/hobbies", {headers:{'X-Api-Key': 'JdZKl3r2aaakVdtczZ5UAw==4XGj9gP5C6Mtz8Dz'}})
            setHobby(data.hobby);
            setInfo(data.link);
            return
        }
        getHobby();
    }, [])

    useEffect(()=>{
        async function getHobby() {
            let {data} = await axios.get("https://api.api-ninjas.com/v1/hobbies", {headers:{'X-Api-Key': 'JdZKl3r2aaakVdtczZ5UAw==4XGj9gP5C6Mtz8Dz'}})
            setHobby(data.hobby);
            setInfo(data.link);
            return
        }
        getHobby();
    }, [another])

	return (
		<div className='d-flex flex-column justify-content-between w-100 h-100 border rounded p-4 pb-3'>
            Try Something New!
            <div className='pb-3 text-center'>
                {hobby}
            </div>
            <div className='row justify-content-center pt-3'>
                <a href={info} target="blank" className="btn btn-primary col-5 mx-1">Learn</a>
                <button className="btn btn-primary col-5 mx-1" onClick={()=>setAnother(!another)}>
                    Another!
                </button>
            </div>
		</div>
	);
}
