import React from 'react';

export function Nav() {
	return (
		<nav className="navbar navbar-expand-lg bg-pink border-bottom py-2 px-5 d-flex justify-content-between">
			<a className="navbar-brand" href="/"><img id="navImg" className="pb-1 mr-0" src="https://64.media.tumblr.com/e2b0cb1983ad17ca2572c712442dfeac/tumblr_inline_ooiggtcjGr1rxgdzt_540.gifv"/>Home</a>
			<div className="d-flex">
				<a className="p-2 nav-link" href="/about">About</a>
				<a className="p-2 nav-link" href="/techBackground">Experience</a>
				{/* <a className="p-2 nav-link" href="/resume">Resume</a> */}
				<a className="p-2 nav-link" href="/contact">Contact</a>
			</div>
		</nav>
	);
}
