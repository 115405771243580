import {Modal, Button} from 'react-bootstrap';

export function ListModal(props) {
   
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ul>
                    {props.list.map((x, i)=> <li key={i}>{x}</li>)}
                </ul>
            </Modal.Body>

            {/* <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}
