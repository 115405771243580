import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Nav } from './pages/components/Navigation.js';
import { Footer } from './pages/components/Footer.js';
import { Home } from './pages/Home.js';
import { About } from './pages/About.js';
import { TechBg } from './pages/TechBg.js';
import { Contact } from './pages/Contact.js';

function App() {
  return (
      <div className="App">
          <Nav />
          <Router className="" >
            <Routes>
              <Route exact path="/" element={<Home/>} />
              <Route exact path="/about" element={<About/>} />
              <Route exact path="/techBackground" element={<TechBg/>} />
              <Route exact path="/contact" element={<Contact/>} />
            </Routes>
          </Router>
          <Footer />
      </div>
  );
}

export default App;
