import React from 'react';
import { Socials } from './components/Socials.js';
import { IGWidget } from './components/IGWidget.js';

export function About() {
	return (
    <div className="w-90 container" >
    <div className="row mt-3">
        <div className="col-6">
            <IGWidget />
        </div>
        <div className="d-flex flex-column text-left justify-content-left col-6">
            {/* <u>About Me:</u> */}
            <p>
            Hello, I'm Kai Qi Chee, a Computer Science student at Stevens Institute of Technology. 
            I'm originally from Malaysia but was born in Beijing and raised in Hong Kong and Shanghai. 
            In 2019, two years after my family and I relocated to California, I attended and graduated high school. 
            Following this I moved to the East Coast to begin my time at Stevens Institute of Technology. 
            <br/>
            In the spring of 2022 I graduated with my bachelors in Computer Science and minor in Science Communication; 
            then continued to pursue a masters in CS as well as graduate certificates in Software Engineering, Software Development and Design, and Databases.
            During my time at Stevens, I've had the wonderful opportunity to work as a TA, tutor, and software startup developer.
            I will be completing graduate school in May 2023 and am happy to say that following this I will be beginning my job 
            as a developer at Comcast NBCUniversal!
            <br/>
            Click around to get to know a little bit more about me and my technical background and
            feel free to send me a message or just say hello thru the contact form.
            <br/>
            <br/>
            <div className='d-flex flex-inline justify-content-between'>
                <p className='width-auto '>-Kai Qi Chee</p>
                <Socials fill="pink" />
            </div>
            </p>
        </div>

    </div>
   
</div>
  );
}
