import React from 'react';
import {Socials} from "./Socials";

export function Footer() {
    // let basicHeart = ❤️;
    // let sparkleHeart = 💖
    // /bg-light px-5 mt-5 mb-0 bottom-0 footer d-flex flex-wrap justify-content-between align-items-center py-3 border-top fixed-bottom w-100
    //footer d-flex flex-wrap justify-content-between border-top px-3 pt-3 fixed-bottom
    return (
        <footer id="footer">
            <p>made with 💖 in Hoboken</p>
            <Socials fill="white"/>
        </footer>
    )
};

