import React, {useState, useEffect}  from 'react';
import Clock from 'react-clock'

export function SingleClock(props) {

	return (
		<div className='d-flex flex-column justify-content-center'>
            {/* <iframe className="pe-none m-auto" src="https://free.timeanddate.com/clock/i8sds0tu/n179/szw160/szh160/hocfff/hbw4/cf100/hgr0/fav0/fiv0/mqc000/mqs3/mql25/mqw6/mqd96/mhc000/mhs3/mhl20/mhw6/mhd96/mmc000/mms3/mml10/mmw2/mmd96/hhw11/hmw11/hmr4/hsl75/hsb22" frameborder="0" width="160" height="160"></iframe> */}

            <Clock className="m-auto react-clock" value={props.time.toLocaleTimeString("en-US", { timeZone: props.timezone })} />
            <p className="font-weight-light m-0 p-0 pt-3 pb-0">{props.location}</p>
            <p className="font-weight-light small  m-0 p-0 py-1">{props.time.toLocaleString("en-US", { timeZone: props.timezone })}</p>
            <p className="font-weight-light font-italic  m-0 p-0 pt-0 small">({props.caption})</p>
		</div>
	)
}