import React, {useState}  from 'react';
import { SingleClock } from './SingleClock';
import Clocks from '../../json/Clocks.json';

export function ClockBar() {
    let [time, setTime] = useState(new Date())

    setInterval(() => {
        setTime(new Date());
    }, 1000)

    return(
        <div className="d-flex d-inline-flex flex-wrap justify-content-around w-100 h-100 pt-2 px-3 pb-3">
            {Clocks.map((city,i) =>             
                <SingleClock location={city.location} caption={city.caption} time={time} timezone={city.timezone} key={i} />
            )}
        </div>
    );
}