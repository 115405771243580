import React, {useState, useEffect} from 'react';
import axios from 'axios';
export function Trivia() {
    let [riddle, setRiddle] = useState(undefined);
    let [answer, setAnswer] = useState(undefined);
    let [showAnswer, setShowAnswer] = useState(false);
    let [another, setAnother] = useState(false);
    useEffect(()=>{
        async function getTrivia() {
            let {data} = await axios.get("https://api.api-ninjas.com/v1/riddles")
            console.log(data[0])
            setRiddle(data[0].question);
            setAnswer(data[0].answer);
            return
        }
        getTrivia();
    }, [])

    useEffect(()=>{

        async function getTrivia() {
            let {data} = await axios.get("https://api.api-ninjas.com/v1/trivia?", {headers:{'X-Api-Key': 'JdZKl3r2aaakVdtczZ5UAw==4XGj9gP5C6Mtz8Dz'}})
            console.log(data[0])
            setRiddle(data[0].question);
            setAnswer(data[0].answer);   
            setShowAnswer(false);         
            return
        }
        getTrivia();
    }, [another])

	return (
		<div className='d-flex flex-column justify-content-between w-100 h-100 border rounded p-4 pb-3'>
            Learn Something New!
            <div className='row'>
                {!showAnswer ?
                    <p className='m-0 p-0'>{riddle}?</p> 
                    : <p className='m-0 p-0'>{answer}</p>
                }
            </div>
            <div className='row justify-content-center'>
                <button className="btn btn-primary col-5 mx-1"  onClick={()=>setShowAnswer(!showAnswer)}>
                    {!showAnswer ? "Answer" : "Riddle"}
                </button>
                <button className="btn btn-primary col-5 mx-1" onClick={()=>setAnother(!another)}>
                    Another!
                </button>
            </div>
		</div>
	);
}

// <div className="card-body">
// <h5 className="card-title">Learn Something New!</h5>
// <p className="card-text">
// {!showAnswer ?
//     <p>{riddle}?</p> 
//     : <p>{answer}</p>
// }
// </p>
// <a href="#" className="card-link">{!showAnswer ? "Answer" : "Riddle"}</a>
// <a href="#" className="card-link">                    Another!</a>
// </div>
// </div>